import { HeaderGoBack } from "@src/components";
import { Modal, useTheme } from "@merit/frontend-components";
import { PolicyRequestActions, PolicyRequestDetails } from "./components";
import { PolicyRequestApprovedModal } from "./components/PolicyRequestApprovedModal";
import { PolicyRequestDeniedModal } from "./components/PolicyRequestDeniedModal";
import { ScrollView, StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import {
  useLastSuccessfulApprovePolicyRequestMutation,
  useLastSuccessfulDenyPolicyRequestMutation,
} from "./hooks";
import { useLingui } from "@lingui/react";
import { useNavigation, useRoute } from "@src/hooks";
import type { ViewStyle } from "react-native";

const MODAL_HEIGHT = 595;
const MODAL_WIDTH = 505;

export const PolicyRequestDetailsDesktop = () => {
  const route = useRoute<"PolicyRequestDetails">();
  const denyPolicyRequestMutation = useLastSuccessfulDenyPolicyRequestMutation(
    route.params.policyId
  );
  const approvePolicyRequestMutation = useLastSuccessfulApprovePolicyRequestMutation(
    route.params.policyId
  );
  const { _ } = useLingui();
  const { theme } = useTheme();
  const navigation = useNavigation();

  const handlePressClose = () => {
    navigation.goHome();
  };

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly headerContainer: ViewStyle;
    readonly policyRequestDetailsContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
      gap: theme.spacing.l,
    },
    headerContainer: {
      backgroundColor: theme.colors.background.white,
    },
    policyRequestDetailsContainer: {
      marginHorizontal: "auto",
      width: 520,
    },
  });

  return (
    <View style={styles.container}>
      <HeaderGoBack containerStyle={styles.headerContainer} title={_(msg`Verification`)} />
      <ScrollView contentContainerStyle={styles.policyRequestDetailsContainer}>
        <PolicyRequestDetails policyId={route.params.policyId} />
      </ScrollView>
      <PolicyRequestActions policyId={route.params.policyId} />
      {approvePolicyRequestMutation?.data !== undefined && (
        <Modal height={MODAL_HEIGHT} onClose={handlePressClose} width={MODAL_WIDTH}>
          <PolicyRequestApprovedModal policyRequest={approvePolicyRequestMutation.data} />
        </Modal>
      )}
      {denyPolicyRequestMutation !== undefined && (
        <Modal height={MODAL_HEIGHT} onClose={handlePressClose} width={MODAL_WIDTH}>
          <PolicyRequestDeniedModal />
        </Modal>
      )}
    </View>
  );
};
