import { unloadAbortController } from "@src/utils";
import { useEffect } from "react";
import { useLogoutOnError, useMeritAuth0 } from "@src/hooks";
import { useQuery } from "@tanstack/react-query";
import { useSignedEntityApi } from "./useSignedEntityApi";
import type { MeritUserInfo } from "@src/types/user";

type QueryKey = readonly ["personalSignedToken", { readonly userId?: MeritUserInfo["entityID"] }];

export const usePersonalSignedTokenQueryKey = (): QueryKey => {
  const { user } = useMeritAuth0();

  return ["personalSignedToken", { userId: user?.entityID }];
};

export const usePersonalSignedToken = () => {
  const { api: signedEntityApi, isReady } = useSignedEntityApi();
  const queryKey = usePersonalSignedTokenQueryKey();
  const { onError } = useLogoutOnError();

  const query = useQuery({
    enabled: isReady,
    queryFn: () => signedEntityApi.getSignedEntityID({}, { signal: unloadAbortController.signal }),
    queryKey,
  });

  useEffect(() => {
    if (query.isError) {
      onError(query.error);
    }
  }, [query.isError, query.error, onError]);

  return query;
};
