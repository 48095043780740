import { useContainersApi, useMerits } from "@src/api/issuance";
import { useMemo } from "react";
import type { Container } from "@merit/issuance-client";

type UseMeritsParams = {
  readonly meritId: Container["id"];
};

const useMeritDetails = ({ meritId }: UseMeritsParams) => {
  const { isReady } = useContainersApi();
  const { data: merits, isError } = useMerits();

  const matchingMerit = useMemo(
    () => (merits ?? []).find(container => container.id === meritId),
    [merits, meritId]
  );

  return {
    matchingMerit,
    showEmptyState: isError || (matchingMerit === undefined && merits !== undefined),
    showLoadingState: merits === undefined || matchingMerit === undefined || !isReady,
  };
};

export { useMeritDetails };
