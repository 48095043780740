import { Body, Link } from "@merit/frontend-components";
import { Trans } from "@lingui/macro";
import { useDeleteMFAEnrollments } from "@src/api/person-experience-backend";
import { useRoute } from "@src/hooks";

type MFAResetProps = {
  readonly onSuccessMFAReset?: () => void;
};

export const MFAReset = ({ onSuccessMFAReset }: MFAResetProps) => {
  const route = useRoute();
  const deleteEnrollments = useDeleteMFAEnrollments();

  return (
    <Body
      testProps={{
        elementName: "EntityMergeBody",
        screenName: route.name,
      }}
    >
      <Trans>
        Unenroll from multi-factor authentication. This will remove MFA from your account.
      </Trans>{" "}
      <Link
        onPress={async () => {
          await deleteEnrollments.mutateAsync();
          onSuccessMFAReset?.();
        }}
        testProps={{
          elementName: "EntityMergeButton",
          screenName: route.name,
        }}
      >
        {deleteEnrollments.isPending ? (
          <Trans>Loading…</Trans>
        ) : (
          <Trans comment="MFA = multi-factor authentication">Remove MFA</Trans>
        )}
      </Link>
    </Body>
  );
};
