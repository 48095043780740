import {
  arMessages,
  deMessages,
  enMessages,
  esMessages,
  filMessages,
  frMessages,
  htMessages,
  koMessages,
  pseudoLocaleMessages,
  ruMessages,
  viMessages,
  zhMessages,
} from "@src/locales";
import type { Messages } from "@lingui/core";

export const DEFAULT_LOCALE = "en";
export const PSEUDO_LOCALE = "pseudo-LOCALE";

export const SUPPORTED_LANGS = [
  "ar",
  "de",
  "en",
  "es",
  "fil",
  "fr",
  "ht",
  "ko",
  "ru",
  "vi",
  "zh",
] as const;
export type SupportedLang = keyof typeof SUPPORTED_LANGS;

// TODO: for language *tags* (e.g. with region, like es-MX, en-GB), need to define both tag and code without the region
export const SUPPORTED_LANGS_AND_MESSAGES: Record<SupportedLang & typeof PSEUDO_LOCALE, Messages> =
  {
    [PSEUDO_LOCALE]: pseudoLocaleMessages,
    ar: arMessages, // Arabic
    de: deMessages, // German
    en: enMessages, // English
    es: esMessages, // Spanish
    fil: filMessages, // Filipino (use this instead of Tagalog per CLDR suggestions)
    fr: frMessages, // French
    ht: htMessages, // Haitian Creole
    ko: koMessages, // Korean
    ru: ruMessages, // Russian
    vi: viMessages, // Vietnamese
    zh: zhMessages, // Chinese (Traditional)
  };

export type SupportedLanguageTag = keyof typeof SUPPORTED_LANGS_AND_MESSAGES;
