import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts, useMeritAuth0 } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotificationsController } from "@src/screens/Home/components/Notifications";
import { usePolicyRequestsApi } from "./usePolicyRequestsApi";
import { useRequestedPolicyRequestsQueryKey } from "./useRequestedPolicyRequests";
import type { PolicyRequest } from "./types";

export const useDenyPolicyRequestMutationKey = () => ["denyPolicyRequest"];

type Options = {
  readonly showErrorToast: boolean;
  readonly showMutatingToast: boolean;
  readonly showSuccessToast: boolean;
};

const defaultOptions: Options = {
  showErrorToast: true,
  showMutatingToast: true,
  showSuccessToast: true,
};

export const useDenyPolicyRequest = (options: Partial<Options> = defaultOptions) => {
  const { sendAlert } = useAlerts();
  const queryKey = useRequestedPolicyRequestsQueryKey();
  const queryClient = useQueryClient();
  const { _ } = useLingui();
  const { api } = usePolicyRequestsApi();
  const { user } = useMeritAuth0();
  const mutationKey = useDenyPolicyRequestMutationKey();
  const { refreshNotifications } = useNotificationsController();

  return useMutation({
    mutationFn: ({ policyId }: { readonly policyId: PolicyRequest["policy"] }) => {
      if (user?.entityID === undefined) {
        Log.error("entityId is undefined");
        throw new Error("entityId is undefined");
      }

      return api.patchPolicyRequest({
        policyID: policyId,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        properties: { status: "Denied" },
        targetID: user.entityID,
      });
    },
    mutationKey,
    onError: (err, { policyId }) => {
      Log.error(`Error denying policyRequest`, { err, policyId });
      if (options.showErrorToast === true) {
        sendAlert({
          id: "useDenyPolicyRequest-Error",
          text1: _(msg`Something went wrong while trying to deny your verification request`),
          type: "error",
        });
      }
    },
    onMutate: () => {
      if (options.showMutatingToast === true) {
        sendAlert({
          id: "useDenyPolicyRequest-Accepting",
          text1: _(msg`Denying…`),
          type: "info",
        });
      }
    },
    onSuccess: () => {
      if (options.showSuccessToast === true) {
        sendAlert({
          id: `useDenyPolicyRequest-Success`,
          text1: _(msg`You successfully denied your verification request`),
          type: "success",
        });
      }

      queryClient.invalidateQueries({ queryKey });
      refreshNotifications();
    },
  });
};
