import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Card } from "@src/components";
import { StyleSheet, View } from "react-native";
import type { FC } from "react";
import type { NotificationLayoutProps } from "./types";

export const NotificationLayout: FC<NotificationLayoutProps> = ({
  body,
  heading,
  leftIcon,
  rightIcon,
}) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      height: "100%",
      justifyContent: "space-between",
      padding: theme.spacing.l,
      width: 440,
    },
    detailsContainer: {
      flex: 1,
    },
    detailsItem: {
      marginBottom: theme.spacing.s,
    },
    iconContainer: {
      marginRight: theme.spacing.m,
      paddingHorizontal: theme.spacing.m,
    },
  });

  return (
    <Card elevation={2} style={styles.container}>
      <View style={styles.iconContainer}>{leftIcon}</View>
      <View style={styles.detailsContainer}>
        <Heading bold level="3" style={styles.detailsItem}>
          {heading}
        </Heading>
        <Body style={styles.detailsItem}>{body}</Body>
      </View>
      <View>{rightIcon}</View>
    </Card>
  );
};
