/* eslint-disable lingui/no-unlocalized-strings */

import {
  FieldLevelCompoundAtomSchema,
  TemplateLevelCompoundAtomSchema,
  TemplateLevelCompoundAtomWithFieldSchema,
} from "./PolicySchema";
import { z } from "zod";

const ErrorResult = z.object({
  formula: z.union([
    TemplateLevelCompoundAtomSchema,
    TemplateLevelCompoundAtomWithFieldSchema,
    FieldLevelCompoundAtomSchema,
  ]),
});

export const PolicyRequestSchema = z.object({
  errorResults: z.array(ErrorResult).optional(),
  owner: z.object({
    name: z.string(),
  }),
  policy: z.string(),
  result: z.boolean().optional(),
  resultMessage: z.string().optional(),
  status: z.enum(["Approved", "Completed", "Denied", "Requested"]),
});
