import { useApprovePolicyRequestMutationKey } from "@src/api/issuance/useApprovePolicyRequest";
import { useMemo } from "react";
import { useMutationState } from "@tanstack/react-query";
import type { MutationState } from "@tanstack/react-query";
import type { PolicyRequest } from "@src/api/issuance";

type UseLastSuccessfulApprovePolicyRequestMutation = (
  policyId: PolicyRequest["policy"]
) => MutationState<PolicyRequest> | undefined;

export const useLastSuccessfulApprovePolicyRequestMutation: UseLastSuccessfulApprovePolicyRequestMutation =
  policyId => {
    const mutationKey = useApprovePolicyRequestMutationKey();

    const approvePolicyRequestMutations = useMutationState<MutationState<PolicyRequest>>({
      filters: {
        exact: true,
        mutationKey,
        predicate: mutation => mutation.state.variables?.policyId === policyId,
        status: "success",
      },
    });

    const approvePolicyRequestMutation = useMemo(
      () => approvePolicyRequestMutations.at(-1),
      [approvePolicyRequestMutations]
    );

    return approvePolicyRequestMutation;
  };
