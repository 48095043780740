import { Body, Link } from "@merit/frontend-components";
import { Trans } from "@lingui/macro";
import { openExternalLink } from "@src/utils";
import { useCreateMFAEnrollmentTicket } from "@src/api/person-experience-backend";
import { useEffect, useState } from "react";
import { useRoute } from "@src/hooks";

type MFAEnrollProps = {
  readonly onPressRefreshStatus: () => void;
};

export const MFAEnroll = ({ onPressRefreshStatus }: MFAEnrollProps) => {
  const route = useRoute();
  const createMFAEnrollmentTicket = useCreateMFAEnrollmentTicket();
  const [isEnrollmentLinkOpened, setIsEnrollmentLinkOpened] = useState(false);

  useEffect(() => {
    if (createMFAEnrollmentTicket.isSuccess) {
      openExternalLink(createMFAEnrollmentTicket.data.url);
      setIsEnrollmentLinkOpened(true);
    }
  }, [createMFAEnrollmentTicket.data, createMFAEnrollmentTicket.isSuccess]);

  const content = (() => {
    if (isEnrollmentLinkOpened) {
      return (
        <>
          <Trans>Please finish MFA enrollment in your browser.</Trans>{" "}
          <Link
            onPress={() => {
              onPressRefreshStatus();
            }}
          >
            <Trans context="action">Refresh Status</Trans>
          </Link>
        </>
      );
    }

    return (
      <>
        <Trans comment="MFA = multi-factor authentication">
          Enroll in multi-factor authentication. This will add MFA to your account.
        </Trans>{" "}
        <Link
          onPress={() => {
            if (createMFAEnrollmentTicket.isPending) {
              return;
            }
            createMFAEnrollmentTicket.mutate();
          }}
          testProps={{
            elementName: "EntityMergeButton",
            screenName: route.name,
          }}
        >
          {createMFAEnrollmentTicket.isPending ? (
            <Trans>Loading…</Trans>
          ) : (
            <Trans comment="MFA = multi-factor authentication">Enroll in MFA</Trans>
          )}
        </Link>
      </>
    );
  })();

  return (
    <Body
      testProps={{
        elementName: "EntityMergeBody",
        screenName: route.name,
      }}
    >
      {content}
    </Body>
  );
};
