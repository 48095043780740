import { Body, Button, TextInput, useTheme } from "@merit/frontend-components";
import { Controller, useForm } from "react-hook-form";
import { ScrollView, StyleSheet, View } from "react-native";
import { SelectInput } from "@src/components";
import { msg, t } from "@lingui/macro";
import { useEffect } from "react";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useRemoveMerit } from "@src/api/issuance";
import { useSendRejectMeritEmail } from "@src/api/person-experience-backend";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import type { I18n } from "@lingui/core";
import type { Merit } from "@src/api/issuance";

const getRemovalReasons = (i18n: I18n) => [
  t(i18n)`This appears to be a duplicate merit`,
  t(i18n)`The information on this merit is incorrect`,
  t(i18n)`I don\u2019t need this merit anymore`,
  t(i18n)`Other`,
  t(i18n)`Spam`,
];

const getSchema = (i18n: I18n) =>
  z.object({
    containerID: z.string().uuid(),
    memberEmailAddress: z.string(),
    memberFirstName: z.string(),
    orgName: z.string(),
    rejectionDescription: z
      .string({ required_error: t(i18n)`Requires at least 20 characters` })
      .trim()
      .min(20, t(i18n)`Requires at least 20 characters`)
      .max(2000),
    rejectionReason: z
      .string({ required_error: t(i18n)({ context: "form error message", message: "Required" }) })
      .refine(value => getRemovalReasons(i18n).includes(value)),
  });

type RemoveMeritFormProps = {
  readonly onRemove?: () => Promise<void> | void;
  readonly onCancel?: () => Promise<void> | void;
  readonly merit: Merit;
};

export const RemoveMeritForm: FC<RemoveMeritFormProps> = ({ merit, onCancel, onRemove }) => {
  const { theme } = useTheme();
  const removeMerit = useRemoveMerit();
  const sendRejectMeritEmail = useSendRejectMeritEmail();
  const getTestProps = useGetTestProps();
  const { _, i18n } = useLingui();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: (values, context, options) => {
      const { rejectionReason, ...rest } = values;

      const transformed = {
        rejectionReason: rejectionReason?.value,
        ...rest,
      };

      return zodResolver(getSchema(i18n))(transformed, context, options);
    },
  });

  useEffect(() => {
    // lingui eslint plugin doesn't work gracefully for dereferencing objects with string literals
    // eslint-disable-next-line lingui/no-unlocalized-strings
    setValue("memberFirstName", merit.fieldMap["First Name"]);
    setValue("containerID", merit.id);
    setValue("memberEmailAddress", merit.fieldMap.Email);
    setValue("orgName", merit.transformedFields.orgName);
  }, [setValue, merit.id, merit.fieldMap, merit.transformedFields.orgName]);

  const styles = StyleSheet.create({
    actionsContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingTop: theme.spacing.l,
    },
    cancelButtonContainer: {
      marginRight: theme.spacing.s,
    },
    container: {
      width: "100%",
    },
    formContainer: {},
    inputContainer: {
      marginBottom: theme.spacing.l,
    },
  });

  const handleCancel = () => {
    onCancel?.();
  };

  const handleRemove = handleSubmit(body => {
    removeMerit.mutate(merit.id, {
      onSettled: () => {
        // The onSuccess handler wont run if the component is unmounted before removeMerit finishes
        onRemove?.();
      },
      onSuccess: () => {
        sendRejectMeritEmail.mutate(body);
      },
    });
  });

  const removalOptions = getRemovalReasons(i18n).map(reason => ({
    label: reason,
    value: reason,
  }));

  // use non-scrolling ScrollView as parent container to allow tap to dismiss keyboard
  return (
    <ScrollView scrollEnabled={false} style={styles.container}>
      <View style={styles.formContainer}>
        <View style={styles.inputContainer}>
          <Controller
            control={control}
            name="rejectionReason"
            render={({ field: { onBlur, onChange, value } }) => (
              <SelectInput
                data={removalOptions}
                label={_(msg({ context: "input label", message: "Removal reason *" }))}
                labelField="label"
                onBlur={onBlur}
                onChange={onChange}
                placeholder={_(msg({ context: "placeholder", message: "Select reason" }))}
                value={value?.label}
                valueField="value"
                {...getTestProps({
                  elementId: "removalReasonInput",
                  elementName: "RemoveMeritForm",
                })}
              />
            )}
          />
          {errors.rejectionReason !== undefined && (
            <Body
              color={theme.colors.text.alert.critical}
              {...getTestProps({
                elementId: "removalReasonError",
                elementName: "RemoveMeritForm",
              })}
            >
              {errors.rejectionReason.message as string}
            </Body>
          )}
        </View>
        <View style={styles.inputContainer}>
          <Controller
            control={control}
            name="rejectionDescription"
            render={({ field: { onBlur, onChange, value } }) => (
              <TextInput
                label={_(msg({ context: "input label", message: "Additional details *" }))}
                maxLength={2000}
                numberOfLines={4}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder={_(
                  msg({ context: "placeholder", message: "Please describe your issue" })
                )}
                value={value}
                {...getTestProps({
                  elementId: "additionalDetailsInput",
                  elementName: "RemoveMeritForm",
                })}
              />
            )}
          />
          {errors.rejectionDescription !== undefined && (
            <Body
              color={theme.colors.text.alert.critical}
              {...getTestProps({
                elementId: "additionalDetailsError",
                elementName: "RemoveMeritForm",
              })}
            >
              {errors.rejectionDescription.message as string}
            </Body>
          )}
        </View>
      </View>
      <View style={styles.actionsContainer}>
        <View style={styles.cancelButtonContainer}>
          <Button
            onPress={handleCancel}
            text={_(msg({ context: "dismiss dialog", message: "Cancel" }))}
            type="secondary"
            {...getTestProps({
              elementId: "cancelButton",
              elementName: "RemoveMeritForm",
            })}
          />
        </View>
        <Button
          onPress={handleRemove}
          text={_(msg({ context: "action", message: "Remove" }))}
          type="destructive"
          {...getTestProps({
            elementId: "removeButton",
            elementName: "RemoveMeritForm",
          })}
        />
      </View>
    </ScrollView>
  );
};
