import { IconSkeleton, TextSkeleton } from "@src/components";
import { NotificationLayout } from "../NotificationLayout";
import { StyleSheet } from "react-native";
import type { FC } from "react";

export const NotificationSkeleton: FC = () => {
  const styles = StyleSheet.create({
    bodyStyle: {
      width: 300,
    },
    iconStyle: {
      height: 32,
      width: 32,
    },
  });

  return (
    <NotificationLayout
      body={<TextSkeleton style={styles.bodyStyle} />}
      heading={<TextSkeleton />}
      leftIcon={<IconSkeleton style={styles.iconStyle} />}
    />
  );
};
