import { DdRum } from "expo-datadog";
import { emptyFn } from "@src/utils";
import { initialize } from "launchdarkly-js-client-sdk";
import { useConfig } from "@src/hooks";
import { useEffect, useState } from "react";
import type { LDClient, LDEvaluationDetail } from "launchdarkly-js-client-sdk";

export const useLaunchDarklyClient = () => {
  const [client, setClient] = useState<LDClient>();
  const config = useConfig();

  useEffect(() => {
    if (config.remote?.launchDarklyClientID === undefined) {
      return emptyFn;
    }

    // eslint-disable-next-line no-underscore-dangle
    const _client = initialize(
      config.remote.launchDarklyClientID,
      { anonymous: true, kind: "user" },
      {
        inspectors: [
          {
            method: (key: string, detail: LDEvaluationDetail) => {
              DdRum.addFeatureFlagEvaluation(key, detail.value);
            },
            name: "dd-inspector",
            type: "flag-used",
          },
        ],
      }
    );

    setClient(_client);

    return () => {
      _client.close();
    };
  }, [config.remote?.launchDarklyClientID]);

  return client;
};
