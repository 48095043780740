import { useConfig } from "./useConfig";
import { useLaunchDarklyClient } from "./useLaunchDarklyClient";
import { useMeritAuth0 } from "./auth";
import { useQuery } from "@tanstack/react-query";
import type { Configuration } from "@src/configuration";
import type { MeritUserInfo } from "@src/types/user";

type FeatureFlags = Configuration["launchDarkly"]["featureFlags"];

type QueryKey = readonly [
  "launchDarkly",
  {
    readonly entityId?: MeritUserInfo["entityID"];
    readonly mobileKey?: string;
    readonly clientId?: string;
  }
];

export const useFeatureFlagsQueryKey = (): QueryKey => {
  const { user } = useMeritAuth0();
  const config = useConfig();

  return [
    "launchDarkly",
    {
      clientId: config.remote?.launchDarklyClientID,
      entityId: user?.entityID,
      mobileKey: config.remote?.launchDarklyMobileKey,
    },
  ];
};

export const useFeatureFlags = () => {
  const queryKey = useFeatureFlagsQueryKey();
  const { user } = useMeritAuth0();
  const client = useLaunchDarklyClient();
  const config = useConfig();

  const result = useQuery<FeatureFlags, unknown, FeatureFlags, QueryKey>({
    enabled: client !== undefined && user?.entityID !== undefined,
    placeholderData: config.launchDarkly.featureFlags,
    queryFn: async ctx => {
      const { entityId } = ctx.queryKey[1];

      if (client === undefined) {
        throw new Error("Launch Darkly client is undefined");
      }

      if (entityId === undefined) {
        throw new Error("entityId is undefined");
      }

      await client.identify({
        key: entityId,
        kind: "user",
      });

      return client.allFlags() as FeatureFlags;
    },
    // The lint rule doesn't seem to like the non-null assertions or conditionals. Client objects
    // are not expected to be in the queryKey
    //
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
  });

  return result;
};
