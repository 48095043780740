import { AlternateEmailsFieldValueSchema } from "@src/api/issuance/schemas/AccountMeritFieldMapSchema";
import { Configuration as ConfigurationCls, ContainersApi } from "@merit/issuance-client";
import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAccountMerit, useEntityMergesApi } from "@src/api/issuance";
import { useAlerts, useConfig, useMeritAuth0, useSecondaryAccount } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMemberApi } from "@src/api/person-experience-backend";
import jwtDecode from "jwt-decode";
import type { Configuration } from "@src/configuration";
import type { MeritUserInfo } from "@src/types/user";
import type { ResponseError } from "@merit/issuance-client";

const ALTERNATE_EMAILS_FIELD_NAME = "Alternate Emails";

type GetSecondaryAccountAlternateEmails = (
  config: Configuration,
  secondaryEntityId: string,
  secondaryAccessToken: string
) => Promise<readonly string[]>;

const getSecondaryAccountAlternateEmails: GetSecondaryAccountAlternateEmails = async (
  config,
  secondaryEntityId,
  secondaryAccessToken
) => {
  // sanity
  if (config.remote?.accountMeritTemplateID === undefined) {
    Log.error("configuration value for accountMeritTemplateId is undefined");
    throw new Error("Invalid configuration");
  }

  const containersApi = new ContainersApi(
    new ConfigurationCls({
      ...config.api.issuance,
      headers: {
        ...config.api.issuance.headers,
        Authorization: `Bearer ${secondaryAccessToken}`,
      },
    })
  );

  const getContainersResponse = await containersApi.getContainers({
    limit: 1,
    recipientId: secondaryEntityId,
    templateId: config.remote.accountMeritTemplateID,
  });

  const secondaryAccountMerit = getContainersResponse.containers?.[0];
  if (secondaryAccountMerit === undefined) {
    Log.error("secondary account merit not found for user merge", {
      secondaryEntityId,
    });
    throw new Error("Account merit not found for secondary account user");
  }

  const alternateEmailsFieldValueRaw = secondaryAccountMerit.fields?.find(
    field => field.name === ALTERNATE_EMAILS_FIELD_NAME
  )?.value;
  if (alternateEmailsFieldValueRaw === undefined) {
    // Alternate Emails template field is optional, usually for an acct merit that has never merged
    return [];
  }

  const parsedAlternateEmailsFieldValue = (() => {
    try {
      return JSON.parse(alternateEmailsFieldValueRaw) as unknown; // TS hates `any`
    } catch (error) {
      Log.error("JSON.parse of alternate emails field failed", {
        error,
        secondaryAccountMeritId: secondaryAccountMerit.id,
      });
      throw new Error("Invalid JSON for secondary Account Merit Alternate Emails field value");
    }
  })();

  const zodParseResult = AlternateEmailsFieldValueSchema.safeParse(parsedAlternateEmailsFieldValue);
  if (!zodParseResult.success) {
    Log.error("zod safeParse of alternate emails JSON failed", {
      secondaryAccountMeritId: secondaryAccountMerit.id,
    });
    throw new Error("Invalid shape for secondary Account Merit Alternate Emails field value");
  }

  return zodParseResult.data.alternates ?? [];
};

const useEntityMerge = () => {
  const config = useConfig();
  const { sendAlert } = useAlerts();
  const { accessToken: primaryAccessToken, idToken: primaryIdToken } = useMeritAuth0();
  const { login } = useSecondaryAccount();
  const { api: entityMergesApi } = useEntityMergesApi();
  const { api: memberApi } = useMemberApi();
  const { data: primaryAccountMerit } = useAccountMerit();
  const { _ } = useLingui();

  const mergeEntities = async () => {
    if (primaryAccountMerit === undefined) {
      // This should never happen, typescript
      Log.error(`Failed to merge entities because there is no account merit`);

      return undefined;
    }

    const loginResponse = await login();
    if (loginResponse === undefined) {
      Log.error(`Failed to login as secondary user for account merge - idToken missing`);
      sendAlert({
        id: "useSecondaryAccount-noIdToken",
        text1: _(msg`There was a problem logging in`),
        type: "error",
      });

      return undefined;
    }
    const {
      accessToken: secondaryAccessToken,
      email: secondaryAccountPrimaryEmail,
      entityId: secondaryEntityId,
    } = loginResponse;

    const getDecodedPrimaryJwt = () => {
      try {
        return jwtDecode<MeritUserInfo>(primaryIdToken);
      } catch (err) {
        Log.error("Failed to decode JWT in useSecondaryAccount", {
          error: String(err),
        });
      }

      return undefined;
    };

    const decodedPrimaryJwt = getDecodedPrimaryJwt();
    if (decodedPrimaryJwt === undefined) {
      return undefined;
    }

    // !IMPORTANT! that this happens prior to the issuance call to merge accts, else the 2ndary acct merit will cease to exist
    // eslint-disable-next-line functional/no-let
    let secondaryAccountAlternateEmails: readonly string[] = [];
    try {
      secondaryAccountAlternateEmails = await getSecondaryAccountAlternateEmails(
        config,
        secondaryEntityId,
        secondaryAccessToken
      );
    } catch {
      return undefined; // error logging occurs inside the get secondary alt emails func
    }

    try {
      await entityMergesApi.createEntityMerge({
        authorization: `Bearer ${primaryAccessToken}`,
        entityID: decodedPrimaryJwt.entityID,
        properties: {
          token: secondaryAccessToken,
        },
      });
    } catch (err: unknown) {
      const error = err as ResponseError;
      Log.error("Failed to merge entities", {
        response: JSON.stringify(error.response),
      });

      sendAlert({
        id: "useEntityMerge-mergeEntities",
        text1: _(msg`There was a problem merging accounts`),
        type: "error",
      });

      return undefined;
    }

    try {
      const currentPrimaryAlternateEmailsValue =
        primaryAccountMerit.fieldMap[ALTERNATE_EMAILS_FIELD_NAME];

      const getPrimaryForUpdate = () => {
        if (currentPrimaryAlternateEmailsValue === undefined) {
          return primaryAccountMerit.fieldMap.Email;
        }

        return currentPrimaryAlternateEmailsValue.primary;
      };

      const getAlternatesForUpdate = () => {
        const secondaryAccountAllEmails = [
          secondaryAccountPrimaryEmail,
          ...secondaryAccountAlternateEmails,
        ];
        if (currentPrimaryAlternateEmailsValue?.alternates === undefined) {
          return secondaryAccountAllEmails;
        }

        return [...currentPrimaryAlternateEmailsValue.alternates, ...secondaryAccountAllEmails];
      };

      await memberApi.updateAccountMeritAlternateEmails({
        properties: {
          alternateEmails: {
            alternates: getAlternatesForUpdate(),
            primary: getPrimaryForUpdate(),
          },
        },
      });
    } catch (err) {
      Log.error("Failed to update alternate emails", {
        error: String(err),
      });

      sendAlert({
        id: "useEntityMerge-updateEmails",
        text1: _(msg`There was a problem with merging Merit accounts`),
        type: "error",
      });

      return secondaryAccountPrimaryEmail;
    }

    sendAlert({
      id: "useEntityMerge-success",
      text1: _(msg`Successfully merged Merit accounts!`),
      type: "success",
    });

    return secondaryAccountPrimaryEmail;
  };

  return {
    mergeEntities,
  };
};

export { useEntityMerge };
