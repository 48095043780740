import { StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { FC } from "react";
import type { MeritListItemLayoutProps } from "./types";

const ISSUER_IMAGE_SIZE = 56;

export const MeritListItemLayout: FC<MeritListItemLayoutProps> = ({
  issuedAt,
  issuerOrgName,
  meritName,
  orgImage,
}) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    imageNameContainer: {
      display: "flex",
      flexBasis: 500,
      flexDirection: "row",
    },
    issuerImageContainer: {
      borderRadius: theme.borderRadii.m,
      height: ISSUER_IMAGE_SIZE,
      marginRight: theme.spacing.l,
      width: ISSUER_IMAGE_SIZE,
    },
    nameContainer: {
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    rightSide: {
      alignItems: "center",
      flexDirection: "row",
    },
    rightSideItem: {
      marginRight: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.imageNameContainer}>
        <View style={styles.issuerImageContainer}>{orgImage}</View>
        <View style={styles.nameContainer}>{meritName}</View>
      </View>
      <View style={styles.rightSide}>
        <View style={styles.rightSideItem}>{issuerOrgName}</View>
        <View style={styles.rightSideItem}>{issuedAt}</View>
      </View>
    </View>
  );
};
