import { PolicyRequestDetailsNormal } from "./PolicyRequestDetailsNormal";
import { PolicyRequestDetailsSkeleton } from "./PolicyRequestDetailsSkeleton";
import { getPolicyIds } from "../utils";
import { usePolicy, useRequestedPolicyRequests } from "@src/api/issuance";
import type { FC } from "react";
import type { Policy } from "@merit/issuance-client";

type PolicyRequestDetailsProps = {
  readonly policyId: Policy["id"];
};

export const PolicyRequestDetails: FC<PolicyRequestDetailsProps> = ({ policyId }) => {
  const policyQuery = usePolicy({ policyId });
  const requestedPolicyRequestsQuery = useRequestedPolicyRequests();

  const policyRequest = requestedPolicyRequestsQuery.data?.find(pr => pr.policy === policyId);
  if (
    requestedPolicyRequestsQuery.isPending ||
    requestedPolicyRequestsQuery.isError ||
    policyQuery.isPending ||
    policyQuery.isError ||
    policyRequest === undefined
  ) {
    return <PolicyRequestDetailsSkeleton />;
  }

  const policyIds = getPolicyIds(policyQuery.data);

  return <PolicyRequestDetailsNormal orgName={policyRequest.owner.name} policyIds={policyIds} />;
};
