import { HeaderGoBack } from "@src/components";
import { PolicyRequestActions, PolicyRequestDetails } from "./components";
import { PolicyRequestApprovedModal } from "./components/PolicyRequestApprovedModal";
import { PolicyRequestDeniedModal } from "./components/PolicyRequestDeniedModal";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import {
  useLastSuccessfulApprovePolicyRequestMutation,
  useLastSuccessfulDenyPolicyRequestMutation,
} from "./hooks";
import { useLingui } from "@lingui/react";
import { useRoute } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

export const PolicyRequestDetailsMobile = () => {
  const route = useRoute<"PolicyRequestDetails">();
  const denyPolicyRequestMutation = useLastSuccessfulDenyPolicyRequestMutation(
    route.params.policyId
  );
  const approvePolicyRequestMutation = useLastSuccessfulApprovePolicyRequestMutation(
    route.params.policyId
  );
  const { _ } = useLingui();
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly headerContainer: ViewStyle;
    readonly modalContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    contentContainer: {
      flex: 1,
    },
    headerContainer: {
      backgroundColor: theme.colors.background.white,
    },
    modalContainer: {
      backgroundColor: theme.colors.background.white,
      height: "100%",
      position: "absolute",
      width: "100%",
    },
  });

  return (
    <View style={styles.container}>
      <HeaderGoBack containerStyle={styles.headerContainer} title={_(msg`Verification`)} />
      <View style={styles.contentContainer}>
        <PolicyRequestDetails policyId={route.params.policyId} />
        <PolicyRequestActions policyId={route.params.policyId} />
        {approvePolicyRequestMutation?.data !== undefined && (
          <View style={styles.modalContainer}>
            <PolicyRequestApprovedModal policyRequest={approvePolicyRequestMutation.data} />
          </View>
        )}
        {denyPolicyRequestMutation !== undefined && (
          <View style={styles.modalContainer}>
            <PolicyRequestDeniedModal />
          </View>
        )}
      </View>
    </View>
  );
};
