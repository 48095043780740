import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Pressable, StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import type { FC } from "react";
import type { NotificationPolicyRequestProps } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

const COUNTER_SIZE = 24;

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "NotificationPolicyRequest";

export const NotificationPolicyRequest: FC<NotificationPolicyRequestProps> = ({
  counterValue,
  policyRequest,
}) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly bold: TextStyle;
    readonly container: ViewStyle;
    readonly counterContainer: ViewStyle;
    readonly counterText: TextStyle;
    readonly marginBottom: ViewStyle;
    readonly row: ViewStyle;
  }>({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    container: {
      flex: 1,
      justifyContent: "space-between",
      padding: theme.spacing.m,
    },
    counterContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.interactive.critical.default,
      borderRadius: COUNTER_SIZE / 2,
      height: COUNTER_SIZE,
      justifyContent: "center",
      width: COUNTER_SIZE,
    },
    counterText: {
      color: theme.colors.background.white,
      fontWeight: theme.fontWeights.semiBold,
    },
    marginBottom: {
      marginBottom: theme.spacing.m,
    },
    row: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });

  const getTestProps = useGetTestProps();
  const navigation = useNavigation();
  const route = useRoute();

  const onPressNotification = () => {
    navigation.navigate("PolicyRequestDetails", { policyId: policyRequest.policy });
  };

  const orgName = policyRequest.owner.name;

  return (
    <Pressable
      onPress={onPressNotification}
      style={styles.container}
      {...getTestProps(
        { elementId: policyRequest.policy, elementName },
        { componentName: "Container" }
      )}
    >
      <Heading
        level="2"
        testProps={{
          elementId: "notificationHeading",
          elementName,
          screenName: route.name,
        }}
      >
        <Trans>You received a verification request</Trans>
      </Heading>
      <Body
        numberOfLines={3}
        style={styles.marginBottom}
        testProps={{
          elementId: "notificationDescription",
          elementName,
          screenName: route.name,
        }}
      >
        <Trans>
          <Body style={styles.bold}>{orgName}</Body> wants to verify your merits
        </Trans>
      </Body>
      <View style={styles.row}>
        <View />
        <View style={styles.counterContainer}>
          <Body
            style={styles.counterText}
            testProps={{
              elementId: "notificationsCountLeft",
              elementName,
              screenName: route.name,
            }}
          >
            {counterValue}
          </Body>
        </View>
      </View>
    </Pressable>
  );
};
