import { DEFAULT_LOCALE, PSEUDO_LOCALE, SUPPORTED_LANGS_AND_MESSAGES } from "@src/utils/i18n";
import { getSupportedLanguageTag } from "@src/utils/i18n/localeMatcher";
import { i18n } from "@lingui/core";
import { useEffect } from "react";
import { useFeatureFlags, useMeritAuth0 } from "@src/hooks";
import { useLocales } from "expo-localization";
import { usePreferencesStore } from "@src/stores";

export const useInitializeLocale = () => {
  const lastStoredLanguageCode = usePreferencesStore(state => state.languageCode);
  const setStoredLanguageCode = usePreferencesStore(state => state.setLanguageCode);
  const setIsLanguageSetFromIdToken = usePreferencesStore(
    state => state.setIsLanguageSetFromIdToken
  );
  const isLanguageSetFromIdToken = usePreferencesStore(state => state.isLanguageSetFromIdToken);
  const { isAuthenticated, isLoading: isAuth0Loading, user } = useMeritAuth0();
  const { data: featureFlags, isFetched: isFeatureFlagsFetched } = useFeatureFlags();
  const locales = useLocales();

  const activateLocale = (locale: string) => {
    i18n.activate(locale);
  };

  const noop = () => {
    /* noop for easy compact consistent early returns in the useEffect below */
  };

  useEffect(() => {
    // initial state
    if (!isFeatureFlagsFetched || isAuth0Loading) {
      if (i18n.locale === "") {
        activateLocale(
          lastStoredLanguageCode === undefined
            ? DEFAULT_LOCALE
            : getSupportedLanguageTag(lastStoredLanguageCode)
        );
      }

      return noop;
    }

    // pseudolocalization, when turned on, overrides all since this is explicitly for testing
    if (featureFlags?.experimentMemberAppPseudolocalization === true) {
      activateLocale(PSEUDO_LOCALE);

      return noop;
    }

    // this case for setting the language based on the user's JWT claim for preferences.languageTag
    const languageTag = user?.preferences?.languageTag;
    if (isAuthenticated && !isLanguageSetFromIdToken && languageTag !== undefined) {
      const supportedLanguageTag = getSupportedLanguageTag(languageTag);
      activateLocale(supportedLanguageTag);
      setStoredLanguageCode(supportedLanguageTag);
      setIsLanguageSetFromIdToken(true);

      return noop;
    }

    if (lastStoredLanguageCode !== undefined && i18n.locale !== lastStoredLanguageCode) {
      // this case is when a user changes the language, and the stored value changes
      activateLocale(lastStoredLanguageCode);

      return noop;
    }

    // auto language detection using expo-localization
    if (featureFlags?.memberAppAllowAutoLanguageDetection === true) {
      // eslint-disable-next-line functional/no-loop-statement
      for (const locale of locales) {
        if (locale.languageCode !== null) {
          if (Object.keys(SUPPORTED_LANGS_AND_MESSAGES).includes(locale.languageTag)) {
            activateLocale(locale.languageTag);

            return noop;
          }
          if (Object.keys(SUPPORTED_LANGS_AND_MESSAGES).includes(locale.languageCode)) {
            activateLocale(locale.languageCode);

            return noop;
          }
        }
      }
    }

    // default case otherwise, if no locale has been set
    if (i18n.locale === "") {
      activateLocale(DEFAULT_LOCALE);
    }

    return noop;
  }, [
    featureFlags?.experimentMemberAppPseudolocalization,
    featureFlags?.memberAppAllowAutoLanguageDetection,
    isFeatureFlagsFetched,
    locales,
    lastStoredLanguageCode,
    user?.preferences?.languageTag,
    isAuth0Loading,
    isLanguageSetFromIdToken,
    setStoredLanguageCode,
    setIsLanguageSetFromIdToken,
    isAuthenticated,
  ]);
};
