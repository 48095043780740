import { useDenyPolicyRequestMutationKey } from "@src/api/issuance/useDenyPolicyRequest";
import { useMemo } from "react";
import { useMutationState } from "@tanstack/react-query";
import type { MutationState } from "@tanstack/react-query";
import type { PolicyRequest } from "@src/api/issuance";

type UseLastSuccessfulDenyPolicyRequestMutation = (
  policyId: PolicyRequest["policy"]
) => MutationState<PolicyRequest> | undefined;

export const useLastSuccessfulDenyPolicyRequestMutation: UseLastSuccessfulDenyPolicyRequestMutation =
  policyId => {
    const mutationKey = useDenyPolicyRequestMutationKey();

    const denyPolicyRequestMutations = useMutationState<MutationState<PolicyRequest>>({
      filters: {
        exact: true,
        mutationKey,
        predicate: mutation => mutation.state.variables?.policyId === policyId,
        status: "success",
      },
    });

    const denyPolicyRequestMutation = useMemo(
      () => denyPolicyRequestMutations.at(-1),
      [denyPolicyRequestMutations]
    );

    return denyPolicyRequestMutation;
  };
