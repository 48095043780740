import { Icon } from "@merit/frontend-components";
import { Image, StyleSheet } from "react-native";
import { Text } from "@src/utils/constants";
import MeritLogo from "@src/assets/images/merit-logotype.png";
import OhioAceLogo from "@src/assets/images/ohio-ace-logo.png";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";

export type OrgLogoProps = {
  readonly merit?: Merit;
};

export const OrgLogo: FC<OrgLogoProps> = ({ merit }) => {
  const styles = StyleSheet.create({
    logo: {
      height: "100%",
      width: "100%",
    },
  });

  if (merit === undefined) {
    return <Icon name="defaultOrg" style={styles.logo} />;
  }

  const orgName = merit.transformedFields.orgName;
  if (orgName === Text.MERIT_COMPANY_NAME) {
    return <Image resizeMode="contain" source={MeritLogo} style={styles.logo} />;
  }
  if (orgName === "Ohio ACE") {
    return <Image resizeMode="contain" source={OhioAceLogo} style={styles.logo} />;
  }

  return <Icon name="defaultOrg" style={styles.logo} />;
};
