import { Button, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import {
  useApprovePolicyRequest,
  useDenyPolicyRequest,
  usePolicy,
  useRequestedPolicyRequests,
} from "@src/api/issuance";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { FC } from "react";
import type { PolicyRequest } from "@src/api/issuance";
import type { ViewStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "PolicyRequestActions";

type PolicyRequestActionsProps = {
  readonly policyId: PolicyRequest["policy"];
};

export const PolicyRequestActions: FC<PolicyRequestActionsProps> = ({ policyId }) => {
  const approvePolicyRequestMutation = useApprovePolicyRequest({ showSuccessToast: false });
  const denyPolicyRequestMutation = useDenyPolicyRequest({ showSuccessToast: false });
  const { _ } = useLingui();
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const policyQuery = usePolicy({ policyId });
  const requestedPolicyRequestsQuery = useRequestedPolicyRequests();

  const styles = StyleSheet.create<{
    readonly actionsContainer: ViewStyle;
    readonly approveButton: ViewStyle;
    readonly container: ViewStyle;
    readonly denyButton: ViewStyle;
  }>({
    actionsContainer: {
      flexDirection: "row",
      gap: theme.spacing.l,
    },
    approveButton: {
      flex: 5,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: theme.spacing.xxl,
    },
    denyButton: {
      flex: 2,
    },
  });

  const handlePressApprove = () => {
    approvePolicyRequestMutation.mutate({ policyId });
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function

  const handlePressDeny = () => {
    denyPolicyRequestMutation.mutate({ policyId });
  };

  const disabled =
    requestedPolicyRequestsQuery.isPending ||
    requestedPolicyRequestsQuery.isError ||
    policyQuery.isPending ||
    policyQuery.isError ||
    requestedPolicyRequestsQuery.data.find(pr => pr.policy === policyId) === undefined ||
    approvePolicyRequestMutation.isPending ||
    denyPolicyRequestMutation.isPending;

  return (
    <View style={styles.container}>
      <View style={styles.actionsContainer}>
        <View style={styles.denyButton}>
          <Button
            disabled={disabled}
            onPress={handlePressDeny}
            text={_(msg`Deny`)}
            type="destructive"
            {...getTestProps({ elementName }, { componentName: "denyButton" })}
          />
        </View>
        <View style={styles.approveButton}>
          <Button
            disabled={disabled}
            onPress={handlePressApprove}
            text={_(msg`Approve`)}
            type="primary"
            {...getTestProps({ elementName }, { componentName: "approveButton" })}
          />
        </View>
      </View>
    </View>
  );
};
