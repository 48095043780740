import { Body, Icon, Link, useTheme } from "@merit/frontend-components";
import { Constants, openExternalLink } from "@src/utils";
import { PolicyRequestDetailsLayout } from "./PolicyRequestDetailsLayout";
import { StyleSheet, View } from "react-native";
import { TemplateExistenceListItem } from "./TemplateExistenceListItem";
import { TemplateFieldsListItem } from "./TemplateFieldsListItem";
import { Trans } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import type { FC } from "react";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";
import type { PolicyIds } from "../utils";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "PolicyRequestDetailsNormal";

type PolicyRequestDetailsNormalProps = {
  readonly orgName: string;
  readonly policyIds: PolicyIds;
};

export const PolicyRequestDetailsNormal: FC<PolicyRequestDetailsNormalProps> = ({
  orgName,
  policyIds,
}) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly bold: TextStyle;
    readonly orgLogo: ImageStyle;
    readonly orgLogoContainer: ViewStyle;
  }>({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    orgLogo: {
      height: "100%",
      width: "100%",
    },
    orgLogoContainer: {
      height: 40,
      width: 40,
    },
  });

  const openTOS = () => {
    openExternalLink(Constants.URLs.TOS);
  };

  const openPrivacy = () => {
    openExternalLink(Constants.URLs.PRIVACY);
  };

  return (
    <PolicyRequestDetailsLayout
      body={
        <>
          <Body {...getTestProps({ elementName }, { componentName: "descriptionBody" })}>
            <Trans>
              The following information is requested. There may be additional conditions not shown.
            </Trans>
          </Body>
          <View>
            {policyIds.templateIds.map(templateId => (
              <TemplateExistenceListItem key={`template-${templateId}`} templateId={templateId} />
            ))}
            {Object.entries(policyIds.templateFieldIdsByTemplateId).map(
              ([templateId, templateFieldIds]) => (
                <TemplateFieldsListItem
                  key={`templateFields-${templateId}`}
                  templateFieldIds={templateFieldIds}
                  templateId={templateId}
                />
              )
            )}
          </View>
        </>
      }
      footer={
        <Body {...getTestProps({ elementName }, { componentName: "finePrintBody" })}>
          <Trans>
            By proceeding, you consent to share the results of this one-time verification with the
            requesting organization. The organization will not be able to view the specific data
            used for verification, only the result of a test run against it. This form is subject to
            our{" "}
            <Link
              onPress={openTOS}
              {...getTestProps({ elementName }, { componentName: "tosLink" })}
            >
              terms of service
            </Link>{" "}
            and{" "}
            <Link
              onPress={openPrivacy}
              {...getTestProps({ elementName }, { componentName: "privacyLink" })}
            >
              privacy notice
            </Link>
            .
          </Trans>
        </Body>
      }
      header={
        <>
          <View style={styles.orgLogoContainer}>
            <Icon name="defaultOrg" style={styles.orgLogo} />
          </View>
          <Body {...getTestProps({ elementName }, { componentName: "titleBody" })}>
            <Trans>
              <Body style={styles.bold}>{orgName}</Body> wants to verify you.
            </Trans>
          </Body>
        </>
      }
    />
  );
};
