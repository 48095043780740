/* eslint-disable @typescript-eslint/require-await, require-await */

import { Configuration as ConfigurationCls, PoliciesApi } from "@merit/issuance-client";
import { getLocalConfig } from "@src/utils";
import { useFeatureFlags, useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

type UsePoliciesAPIReturn = {
  readonly api: PoliciesApi;
  readonly isReady: boolean;
  readonly isMock: boolean;
};

const usePoliciesApi = (): UsePoliciesAPIReturn => {
  const { accessToken } = useMeritAuth0();
  const { data: featureFlags } = useFeatureFlags();

  if (featureFlags?.showMemberAppVerifications !== true) {
    return {
      api: {
        getPolicy: async () => undefined,
      } as unknown as PoliciesApi,
      isMock: true,
      isReady: true,
    };
  }

  return {
    api: new PoliciesApi(
      new ConfigurationCls({
        ...config.api.issuance,
        headers: {
          ...config.api.issuance.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isMock: false,
    isReady: accessToken !== "",
  };
};

export { usePoliciesApi };
